import { Injectable } from '@angular/core';
import { chevron } from './icon.map';

const env = require('src/environments/environment');

@Injectable()
export class DataStampaGeneratorService {
  private readonly generalWidth: number = 600;

  private readonly fBlue = '#003a78';
  private readonly fWhite = '#f5f5f5';
  // white sections
  private readonly shippingLabelId = 'Shipping.png';
  private readonly defenseLabelId = 'Difesa.png';
  private readonly cdpLabelId = 'CDP.png';
  private readonly economyLabelId = 'Economia&Politica.png';
  private readonly foreignLabelId = 'Esteri.png';

  generateMail(mailTemplate, content): string {
    const { quiFincantieriSection, whiteSections, primes } = content;

    let mailHTML = '';

    mailHTML += this.generateHeader();

    mailHTML += this.generatePodcastBannerSection();

    if (mailTemplate.content.quiFincantieriSection && quiFincantieriSection && quiFincantieriSection.text && quiFincantieriSection.text.length > 10) {
      mailHTML += this.generateQuiFincantieriSection(quiFincantieriSection);
    }

    if (content.teleborsa && content.teleborsa.length > 0) {
      mailHTML += this.generateTeleborsaSection(content.teleborsa);
    }

    if (mailTemplate.content.whiteSections && whiteSections) {
      mailHTML += this.generateWhiteSections(whiteSections, mailTemplate.content.whiteSections.globalStyle);
    }

    mailHTML += this.generatePodcastSection();

    if (mailTemplate.content.primes && primes) {
      mailHTML += this.generatePrimes(primes, mailTemplate.content.primes.globalStyle);
    }

    const pressLink =
      mailTemplate.content.link_press_complete &&
      mailTemplate.content.link_press_complete.links &&
      mailTemplate.content.link_press_complete.links[0];
    mailHTML += this.generateFooter({ id: mailTemplate.content.footer.id, pressLink: pressLink && pressLink.link });

    mailHTML += this.generateCopyright();

    return mailHTML;
  }

  private getFormattedAnchorTag(color: string, htmlContent: string): string {
    let htmlContentModified = htmlContent.replace(
      /<a (.*?)/gim,
      `<a style="font-family: Helvetica; color: ${color};" `
    );
    htmlContentModified = htmlContentModified.replace(
      /<p(.*?)/gim,
      `<p style="margin-top: 10px; margin-bottom: 16px;" `
    );
    return htmlContentModified;
  }

  private generateHeader(): string {
    let apiUrl = env.environment.apiUrl.replace('api.', '');
    apiUrl = apiUrl.replace('cms/', '');
    return `
      <table style="border-collapse: collapse" align="center" width="${this.generalWidth}">
        <tbody>
          <tr>
            <td>
              <img width="${this.generalWidth}" src="${apiUrl}/assets/layout/images/Header.png?width=${this.generalWidth}" />
            </td>
          </tr>
        </tbody>
      </table>
      `;
  }

  private generateFooter({ id, pressLink }): string {
    return `
      <table style="border-collapse: collapse" align="center" width="${this.generalWidth}">
        <tbody>
          <tr>
            <td>
              <a href="${pressLink}">
                <img width="${this.generalWidth}" src="${env.environment.apiUrl}network-content/medias/resize/${id}?width=${this.generalWidth}" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      `;
  }

  generatePodcastBannerSection() {
    return `
      <table width="${this.generalWidth}" style="table-layout: fixed; margin-top: 4px; border-collapse: collapse;" align="center">
        <tbody>
          <tr width="${this.generalWidth}" style="margin-top:4px;">
             <td style="padding-left: 16px; padding-right: 16px; padding-top: 10px; padding-bottom: 10px; 
              color: ${this.fBlue}; font-family: Helvetica; font-size: 16px; line-height: 24px; background-color: #f0f4f8; height: 35px;">
             <b>Da oggi Fincantieri Daily News si arricchisce:</b> oltre alla consueta rassegna stampa, 
             avrete la possibilità di <b>ascoltare le principali novità legate al mondo dell’attualità 
             attraverso un podcast.</b> Lo trovate dopo la sezione Esteri.<br/><br/>
            Buon ascolto a tutti!
            </td>
          </tr>
        </tbody>
      </table>
    `
  }

  generatePodcastSection(): string {
    let apiUrl = env.environment.apiUrl.replace('api.', '');
    apiUrl = apiUrl.replace('cms/', '');
    const podcastImage = `${apiUrl}/assets/layout/images/Podcast.png?width=${this.generalWidth}`;
    return `
      <table width="${this.generalWidth}" style="table-layout: fixed; margin-top: 4px; border-collapse: collapse;" align="center">
        <tbody>
          <tr width="${this.generalWidth}" style="margin-top:4px;">
            <td>
            <a href="https://www.voicetopress.it/podcast/podcastfincantieri.mp3" target="_blank">
              <img width="${this.generalWidth}" src="${podcastImage}" style="background-color: ${this.fBlue}; height: 35px; padding: 0;"/>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    `
  }

  private generateCopyright(): string {
    return `<table align="center" border="0" cellpadding="0" cellspacing="0" style="max-width:100%;min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%" width="100%">
    <tbody>
      <tr>
        <td valign="top" style="padding-top:0;padding-right:18px;padding-bottom:9px;padding-left:18px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;word-break:break-word;color:#656565;font-family:Helvetica;font-size:12px;line-height:150%;text-align:center">
          <em>Copyright © ${new Date().getFullYear()}: Data Stampa per
            Fincantieri. Diritti riservati.</em><br>
          <div>
            <span>Fincantieri</span>
            <div>
              <div>Via Genova, 1</div>
              <span>Trieste</span>,
              <span>Ts</span>
              <span> 34121</span>
              <div>Italy</div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>`;
  }

  private generateQuiFincantieriSection({ title, text }): string {
    return `
      <table style="table-layout: fixed; border-collapse: collapse; background-color: ${this.fBlue
      }; margin-top:4px;" align="center" width="${this.generalWidth}">
        <tbody>
        ${this.generateSectionTitle({ text: title })}
          <tr>
            <td style="padding-left: 16px; padding-right: 16px; padding-top: 0; padding-bottom: 0; color: ${this.fWhite
      }; font-family: Helvetica; font-size: 16px; line-height: 24px;">
             ${this.getFormattedAnchorTag(this.fWhite, text)}
            </td>
          </tr>
        </tbody>
      </table>
      `;
  }

  private generateTeleborsaSection(teleborsaText): string {
    // replace numbers -12.34 with "-12.34"
    var teleborsaTextOKJson = teleborsaText.replace(/[\d-]+(.\d+)?/g, '"$&"');
    teleborsaTextOKJson = teleborsaTextOKJson.replace(/\\/g, '');
    teleborsaTextOKJson = teleborsaTextOKJson.trim();
    let teleborsaData = JSON.parse(teleborsaTextOKJson);

    // Format date
    const today = this.timestampToDateString(parseInt(teleborsaData.timeStamp));

    var ftseMibColor = teleborsaData.ftsemib.percentChange.toString().includes('-') ? 'red' : 'green';
    var fincantieriColor = teleborsaData.percentChange.toString().includes('-') ? 'red' : 'green';
    let apiUrl = env.environment.apiUrl.replace('api.', '');
    apiUrl = apiUrl.replace('cms/', '');
    const whiteBackground = `${apiUrl}/assets/layout/images/white.png?width=${this.generalWidth}`;

    return `
      <table style="table-layout: fixed; border-collapse: collapse; text-align: center; height: 7px !important;" align="center" width="${this.generalWidth}">
        <tbody>
          <tr>
            <td>
              <img src="${whiteBackground}" style="margin: 0 auto; height: 6px; padding: 0; width:${this.generalWidth}px;"/>
            </td>
          </tr>
        </tbody>
      </table>
      <table style="table-layout: fixed; border-collapse: collapse; background-color: ${this.fBlue
      }; text-align: center;" 
      align="center" width="${this.generalWidth}">
        <thead>
          ${this.generateTeleborsaTitle({ text: today })}
        </thead>
        <tbody>
          <tr>
            <td style="padding-left: 16px; padding-right: 16px; padding-top: 10px; padding-bottom: 10px; background-color: ${this.fWhite
      }; font-family: Helvetica; font-size: 16px; line-height: 24px; border: 1px solid #003a78; border-right: 2px solid #003a78; text-align: center; width: 50%;">
              <span style="color: ${this.fBlue}; text-transform: uppercase; font-weight: 600;">FTSE MIB</span>
              <br>
              <span style="color: ${ftseMibColor}; font-weight: 600;">${teleborsaData.ftsemib.percentChange}%</span>
            </td>
            <td style="padding-left: 16px; padding-right: 16px; padding-top: 10px; padding-bottom: 10px; background-color: ${this.fWhite
      }; font-family: Helvetica; font-size: 16px; line-height: 24px; border: 1px solid #003a78; text-align: center; width: 50%;">
              <span style="color: ${this.fBlue}; text-transform: uppercase; font-weight: 600;">FINCANTIERI</span>
              <br>
              <span style="color: ${fincantieriColor}; font-weight: 600;">€${teleborsaData.lastPrice}(${teleborsaData.percentChange}%)
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    `;
  }

  private timestampToDateString(timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString('it-IT', { month: 'long' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  private generateWhiteSections(sections, style): string {
    const check = style.background.id ? true : false;
    const marginTop = check ? 'margin-top:4px;' : 'margin-top:0px';

    const sectionsLabelIds = [];

    if (sections.filter(section => section.title.trim() === 'SHIPPING').length > 0) {
      sectionsLabelIds.push(this.shippingLabelId);
    }
    if (sections.filter(section => section.title.trim() === 'DIFESA').length > 0) {
      sectionsLabelIds.push(this.defenseLabelId);
    }
    if (sections.filter(section => section.title.trim() === 'CDP').length > 0) {
      sectionsLabelIds.push(this.cdpLabelId);
    }
    if (sections.filter(section => section.title.trim() === 'ECONOMIA E POLITICA').length > 0) {
      sectionsLabelIds.push(this.economyLabelId);
    }
    if (sections.filter(section => section.title.trim() === 'ESTERI').length > 0) {
      sectionsLabelIds.push(this.foreignLabelId);
    }

    return `
      ${sections
        .map(({ title, text }, index) => {
          const bodyText = `
          <table style="${marginTop}" width="${this.generalWidth
            }" style="table-layout: fixed; border-collapse: collapse;" align="center">
            <tbody>
              ${this.generateSectionTitle({ background: sectionsLabelIds[index], text: title })}
            </tbody>
          </table>
          <table style="table-layout: fixed; border-collapse: collapse; background-color: ${this.fWhite
            }" align="center" width="${this.generalWidth}">
            <tbody>
              <tr>
                <td style="border-right: 1px solid ${this.fBlue}; border-left: 1px solid ${this.fBlue
            }; border-bottom: 1px solid ${this.fBlue
            }; padding-left: 16px; padding-right: 16px; font-family: Helvetica; font-size: 16px; line-height: 24px;">
                  ${this.getFormattedAnchorTag(this.fBlue, text)}
                </td>
              </tr>
            </tbody>
          </table>
          `;
          return bodyText;
        })
        .join('')}
     `;
  }

  private generatePrimes({ title, content }, style): string {
    let articleHTML = '';
    let apiUrl = env.environment.apiUrl.replace('api.', '');
    apiUrl = apiUrl.replace('cms/', '');
    if (content.length) {
      const backgroundSettings = `${apiUrl}/assets/layout/images/PrimePagine.png?width=${this.generalWidth}`;
      articleHTML += `
      <table width="${this.generalWidth}" style="table-layout: fixed; margin-top: 4px; border-collapse: collapse;" align="center">
        <tbody>
          <tr width="${this.generalWidth}" style="margin-top:4px;">
            <td>
              <img width="${this.generalWidth}" src="${backgroundSettings}" style="background-color: ${this.fBlue}; height: 35px; padding: 0;"/>
            </td>
          </tr>
        </tbody>
      </table>
      <table width="${this.generalWidth}" style="table-layout: fixed; border-collapse: collapse;" align="center">
        <tbody>`;
      content.forEach(prime => {
        articleHTML += `
        <tr style="border: 1px solid ${this.fBlue}">
            <td style="text-align: center; width: 100px; font-size: 16px; border-left: 1px solid ${this.fBlue}; border-top: 1px solid ${this.fBlue}; border-bottom: 1px solid ${this.fBlue}; vertical-align: top">
              <img src=${chevron} width="50" height="50" />
              </td>
              <td style="width: 400px; border-top: 1px solid ${this.fBlue}; border-bottom: 1px solid ${this.fBlue}; border-right: 1px solid ${this.fBlue}; font-size: 16px; color: ${this.fBlue}"><a style="font-family: Helvetica; color: ${this.fBlue};" href="${prime.link}">${prime.title}</a> ${prime.text}</td>
            </tr>`;
      });
      articleHTML += `</tbody></table> `;
    }
    return articleHTML;
  }

  private generateSectionTitle({ background = null, text }): string {
    let apiUrl = env.environment.apiUrl.replace('api.', '');
    apiUrl = apiUrl.replace('cms/', '');
    const backgroundSettings = background
      ? `${apiUrl}/assets/layout/images/${background}?width=${this.generalWidth}`
      : 'unset';
    const paddingTop = background ? '0' : '16px';
    const withImage = `
      <tr width="${this.generalWidth}" style="margin-top: 4px;">
        <td>
          <img width="${this.generalWidth}" src="${backgroundSettings}" style="background-color: ${this.fBlue}; height: 35px; padding: 0;"/>
        </td>
      </tr>
    `;

    const withoutImage = `
      <tr width="${this.generalWidth} style="background-color: ${this.fBlue}; height: 35px; padding: 0;">
        <td style="padding-top: ${paddingTop}; padding-left: 16px; font-weight: bold; font-family: Helvetica; font-size: 24px; color: ${this.fWhite};">
          ${text}
        </td>
      </tr>
    `;

    const selectedImage = background ? withImage : withoutImage;
    return selectedImage;
  }

  private generateTeleborsaTitle({ text }): string {
    return `
      <tr width="${this.generalWidth} style="background-color: ${this.fBlue}; height: 35px; padding: 0;">
        <th colspan="2" style="padding-top: 10px; padding-bottom: 8px; padding-left: 16px; font-weight: bold; font-family: Helvetica; font-size: 14px; color: ${this.fWhite};">
          ${text}
        </th>
      </tr>
    `;
  }
}
